export default function customRadioButtons () {
  const radioFormParents = Array.from(document.querySelectorAll('.vjs-custom-radio-button-form'))
  radioFormParents.forEach(radioFormParent => styleRadioFormOnClick(radioFormParent))
}

function styleRadioFormOnClick (radioFormParent) {
  const radioButtons = Array.from(radioFormParent.querySelectorAll('.vjs-custom-radio-button'))
  radioButtons.forEach(radioButton => {
    radioButton.addEventListener('click', e => checkAndStyleSelected(radioButton, radioButtons, e))
  })
}

function checkAndStyleSelected (selected, deselectedOptions, e) {
  deselectedOptions.forEach(styleDeselected)
  if (e.target.tagName !== 'INPUT') checkSelected(selected)
  styleSelected(selected)
}

function checkSelected (selected) {
  const selectedRadio = selected.querySelector('input[type=radio]')
  if (selectedRadio) {
    selectedRadio.checked = true
    selectedRadio.dispatchEvent(new Event('change', { bubbles: true }))
  }
}

function styleSelected (button) {
  if (button.classList.contains('vjs-form-checkable-tile')) {
    button.classList.add('vsd-form-checkable-tile-checked')
  } else {
    button.style.borderWidth = '1.6px'
  }
}

function styleDeselected (button) {
  if (button.classList.contains('vjs-form-checkable-tile')) {
    button.classList.remove('vsd-form-checkable-tile-checked')
  } else {
    button.style.borderWidth = '1px'
  }
}
